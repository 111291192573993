<template>
  <div>
    <div class="mx-auto max-w-4xl text-center">
      <h2 class="text-base font-semibold leading-7 text-indigo-600">
        {{ $t('homepage.pricing.subtitle') }}
      </h2>
      <p
        class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"
      >
        {{ $t('homepage.pricing.title') }}
      </p>
    </div>
    <p
      class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600"
    >
      {{ $t('homepage.pricing.lead') }}
    </p>
    <div class="mt-16 flex justify-center">
      <RadioGroup
        id="radio-group-payment-frequency"
        v-model="frequency"
        class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
      >
        <RadioGroupLabel
          id="radio-group-label-payment-frequency"
          class="sr-only"
          >Payment frequency</RadioGroupLabel
        >
        <RadioGroupOption
          v-for="option in frequencies"
          :id="'radio-group-option-frequency-' + option.value"
          v-slot="{ checked }"
          :key="option.value"
          as="template"
          :value="option"
        >
          <div
            :class="[
              checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
              'cursor-pointer rounded-full px-2.5 py-1',
            ]"
          >
            <span>{{ option.label[locale] }}</span
            ><span
              v-if="option.reduction"
              class="ml-1.5 text-[0.625rem] rounded-full py-1 px-1.5"
              :class="
                checked
                  ? 'text-white bg-blue-500'
                  : 'text-gray-900 bg-yellow-200/90'
              "
              >{{ option.reduction }}</span
            >
          </div>
        </RadioGroupOption>
      </RadioGroup>
    </div>
    <div
      class="mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-10 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2"
    >
      <div
        v-for="(tier, tierIdx) in tiers"
        :key="tier.id"
        :class="[
          tier.featured
            ? 'relative bg-white shadow-2xl'
            : 'bg-white/60 sm:mx-8 lg:mx-0',
          tier.featured
            ? ''
            : tierIdx === 0
              ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
              : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
          'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
        ]"
      >
        <h3
          :id="tier.id"
          class="text-base font-semibold leading-7 text-indigo-600"
        >
          {{ tier.name }}
        </h3>
        <p class="mt-4 flex items-baseline gap-x-2">
          <span class="text-5xl font-bold tracking-tight text-gray-900">{{
            tier.price[frequency.value]
          }}</span>
          <span v-if="!tier.hideFrequency" class="text-base text-gray-500">{{
            frequency.priceSuffix[locale]
          }}</span>
        </p>
        <p class="mt-6 text-base leading-7 text-gray-600">
          {{ tier.description[locale] }}
        </p>
        <ul
          role="list"
          class="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10"
        >
          <li
            v-for="feature in tier.features[locale]"
            :key="feature"
            class="flex gap-x-3"
          >
            <SvgIcon
              name="check"
              type="solid"
              class="h-6 w-5 flex-none text-indigo-600"
              aria-hidden="true"
            />
            {{ feature }}
          </li>
          <li
            v-for="feature in tier.superFeatures[locale]"
            :key="feature"
            class="flex gap-x-3"
          >
            <SvgIcon
              name="sparkles"
              type="solid"
              class="h-6 w-5 flex-none text-yellow-500"
              aria-hidden="true"
            />
            {{ feature }}
          </li>
          <li
            v-for="feature in tier.nos[locale]"
            :key="feature"
            class="flex gap-x-3"
          >
            <SvgIcon
              name="no-symbol"
              type="outline"
              class="h-6 w-5 flex-none stroke-2 text-gray-400"
              aria-hidden="true"
            />
            {{ feature }}
          </li>
          <li
            v-for="feature in tier.information[locale]"
            :key="feature"
            class="flex gap-x-3"
          >
            <SvgIcon
              name="information-circle"
              type="solid"
              class="h-6 w-5 flex-none stroke-2 text-gray-500"
              aria-hidden="true"
            />
            {{ feature }}
          </li>
        </ul>
        <div
          :aria-describedby="tier.id"
          :class="[
            tier.featured
              ? 'bg-indigo-600 text-white shadow hover:bg-indigo-500'
              : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
            'flex justify-center items-cente mt-8 rounded-md py-2.5 px-3.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10 cursor-pointer',
          ]"
          @click="sendForm(tier.priceId[frequency.value])"
        >
          <svg
            v-if="loading && tier.featured"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {{
            isAuthenticated && !isQuoPro && tier.featured
              ? tier.ctaUpgrade[locale]
              : tier.cta[locale]
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const localePath = useLocalePath()
const { locale } = useI18n()

// const user = useSupabaseUser()
// const supabase = useSupabaseClient()

const loading = ref(false)

const route = useRoute()

const authStore = useAuthStore()
const { chosenPriceId } = storeToRefs(authStore)

const profileStore = useProfileStore()
const { isAuthenticated, isQuoPro, profile } = storeToRefs(profileStore)

// read query param chosenproduct and store it in computed property
const chosenProductId = computed(() => {
  if (route.query.chosenproduct) {
    return route.query.chosenproduct
  } else {
    return null
  }
})

const frequencies = [
  {
    value: 'monthly',
    label: { en: 'Monthly' },
    reduction: null,
    priceSuffix: { en: '/month' },
  },
  {
    value: 'annually',
    label: { en: 'Annually' },
    reduction: '-35%',
    priceSuffix: { en: '/year' },
  },
]

const frequency = ref(frequencies[0])

const isProduction = computed(() => {
  const env = process.env.NODE_ENV
  if (!env) return false
  if (env === 'development') return false
  return true
})

const priceId = computed(() => {
  if (isProduction.value) {
    return {
      monthly: 'price_1NopKrI3IswONDDDg0HftgNO',
      annually: 'price_1N9lRsI3IswONDDD9xXbpjRZ',
    }
  } else {
    return {
      monthly: 'price_1NomrRI3IswONDDDEBryR5At',
      annually: 'price_1N9r2LI3IswONDDDm5hHHJT4',
    }
  }
})

const currentFrequency = computed(() => {
  if (!profile.value) return null
  if (!priceId.value) return null
  if (profile.value.plan && profile.value.plan.id) {
    if (profile.value.plan.id === priceId.value.monthly) {
      return 'monthly'
    } else if (profile.value.plan.id === priceId.value.annually) {
      return 'annually'
    }
  } else return null
})

const offeredTiers = computed(() => {
  if (isAuthenticated.value && !isQuoPro.value) {
    const filteredTiers = tiers.filter((tier) => tier.id !== 'quo-basic')
    return filteredTiers
  } else {
    return tiers
  }
})

const tiers = [
  {
    name: 'Quo Pro',
    id: 'quo-pro',
    price: { monthly: 'CHF 8', annually: 'CHF 60' },
    priceId: priceId.value,
    description: {
      en: 'The perfect plan for active adventures and travelers who want access to all of Quo features.',
    },
    features: {
      en: [
        'Add new public places, create lists, add notes, make lists, and do checkins',
        'Become a Quo Pro with member number & badge',
        'Priority access to new features',
        'Support the development of Quo',
      ],
    },
    superFeatures: {
      en: ['Share the subscription with your partner for free'],
    },
    nos: { en: [] },
    information: { en: [] },
    featured: true,
    cta: { en: 'Subscribe now' },
    ctaUpgrade: { en: 'Upgrade now' },
    hideFrequency: false,
  },
  {
    name: 'Quo Basic',
    id: 'quo-basic',
    price: { monthly: 'free', annually: 'free' },
    priceId: {
      monthly: null,
      annually: null,
    },
    description: {
      en: 'Surfing the map is all that you want? Then this is the plan for you.',
    },
    features: {
      en: [
        'Search and see all public places on the map',
        'Limited to two lists: Favorites and Bucket List',
      ],
    },
    superFeatures: { en: [] },
    nos: { en: ['No adding of new places, comments, or likes'] },
    information: {
      en: ['Choose this plan to be upgrade to Quo Pro by your partner'],
    },
    featured: false,
    cta: { en: 'Start now' },
    hideFrequency: true,
  },
]

// add current tier to tiers

const sendForm = async (priceId) => {
  if (isAuthenticated.value && !isQuoPro.value) {
    loading.value = true
    const { data } = await useFetch('/api/stripe/create-checkout-session', {
      headers: useRequestHeaders(['cookie']),
      method: 'post',
      body: { priceId },
    })
    if (data.value) {
      await navigateTo(data.value, { redirectCode: 303, external: true })
      loading.value = false
    }
  } else {
    loading.value = true
    chosenPriceId.value = priceId
    navigateTo(localePath({ name: 'auth-register' }))
    loading.value = false
  }
}
</script>
